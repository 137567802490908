import SiteSettingService from "@/core/services/site-setting/SiteSettingService";
const siteSettingService = new SiteSettingService();

export const ColorsVariableMixin = {

    data() {
        return {
            authLoading:true,
            setting: {

            },
            colors_variables: {
                login:{},
                header:{},
                side_bar:{},
                button:{},

            },
            login_bg_path: null,
            login_bg_color: null,
            college_color: null,
            slogan_color: null,
            logo_path: null,


        }
    },

    methods: {
        getSetting() {
            siteSettingService.show().then(response => {
                this.setting = response.data;
                if (this.setting && this.setting.id) {
                    if (this.setting.logo_path && this.setting.logo_path['real']) {
                        this.logo_path = this.setting.logo_path['real'];
                    }
                    if (this.setting.colors_variables){
                        this.colors_variables = JSON.parse(this.setting.colors_variables);
                        this.setStyleVariable();
                    }
                    if (this.setting){
                        this.handleIcon()
                    }


                }
            });
        },
        // getColors() {
        //     siteSettingService.show().then(response => {
        //         this.setting = response.data;
        //         if (this.setting && this.setting.id) {
        //             if (this.setting.logo_path && this.setting.logo_path['real']) {
        //                 this.logo_path = this.setting.logo_path['real'];
        //             }
        //         }
        //     }).catch(err => {
        //         console.log('here');
        //         // console.log(err)
        //     })
        // },
        // handleIcon() {
        //     // accessing the link tag
        //     if (this.setting.fav_icon_path && this.setting.fav_icon_path['real']){
        //         const favicon = document.getElementById("favicon");
        //         favicon.href = this.setting.fav_icon_path['real'];
        //     }
        //     document.title = this.setting.tagline || 'Education Management System';
        // },
        // setStyleVariable() {
        //
        //
        //     document.documentElement.style.setProperty('--sidebar-primary-color', this.colors_variables.side_bar.primary_color);
        //     document.documentElement.style.setProperty('--sidebar-secondary-color', this.colors_variables.side_bar.secondary_color);
        //     document.documentElement.style.setProperty('--sidebar-primary-text-color', this.colors_variables.side_bar.primary_text_color);
        //     document.documentElement.style.setProperty('--sidebar-secondary-text-color', this.colors_variables.side_bar.secondary_text_color);
        //
        //     document.documentElement.style.setProperty('--header-primary-color', this.colors_variables.header.primary_color);
        //     document.documentElement.style.setProperty('--header-secondary-color', this.colors_variables.header.secondary_color);
        //     document.documentElement.style.setProperty('--header-primary-text-color', this.colors_variables.header.primary_text_color);
        //     document.documentElement.style.setProperty('--header-secondary-text-color', this.colors_variables.header.secondary_text_color);
        //
        //     document.documentElement.style.setProperty('--button-primary-color', this.colors_variables.button.primary_color);
        //     document.documentElement.style.setProperty('--button-secondary-color', this.colors_variables.button.secondary_color);
        //     document.documentElement.style.setProperty('--button-primary-text-color', this.colors_variables.button.primary_text_color);
        //     document.documentElement.style.setProperty('--button-secondary-text-color', this.colors_variables.button.secondary_text_color);
        // },

        getColors() {
            siteSettingService.getColorsVariables().then((response) => {
                this.setting = response.data;
                if (this.setting && this.setting.id) {
                    if (this.setting.colors_variables) {
                        this.colors_variables = JSON.parse(this.setting.colors_variables);
                        this.setStyleVariable();
                    }
                    if (this.setting.login_bg_path && this.setting.login_bg_path['real']) {
                        this.login_bg_path = this.setting.login_bg_path['real'];
                    }
                    if (this.setting.logo_path && this.setting.logo_path['real']) {
                        this.logo_path = this.setting.logo_path['real'];
                    }
                    if (this.setting.colors_variables_json_values && this.setting.colors_variables_json_values['login']) {
                        this.login_bg_color = this.setting.colors_variables_json_values['login']['bg_color'];
                    }
                    if (this.setting.colors_variables_json_values && this.setting.colors_variables_json_values['login']) {
                        this.college_color = this.setting.colors_variables_json_values['login']['primary_color'];
                    }
                    if (this.setting.colors_variables_json_values && this.setting.colors_variables_json_values['login']) {
                        this.slogan_color = this.setting.colors_variables_json_values['login']['secondary_color'];
                    }
                    if (this.setting) {
                        this.handleIcon()
                    }
                    this.authLoading=false


                }
            }).catch(err => {
                console.log(err)
            })
        },
        handleIcon() {
            // accessing the link tag
            if (this.setting.fav_icon_path && this.setting.fav_icon_path['real']) {
                const favicon = document.getElementById("favicon");
                favicon.href = this.setting.fav_icon_path['real'];
            }
            document.title = this.setting.tagline || 'Education Management System';
        },
        setStyleVariable() {

            document.documentElement.style.setProperty('--sidebar-primary-color', this.colors_variables.side_bar.primary_color);
            document.documentElement.style.setProperty('--sidebar-secondary-color', this.colors_variables.side_bar.secondary_color);
            document.documentElement.style.setProperty('--sidebar-primary-text-color', this.colors_variables.side_bar.primary_text_color);
            document.documentElement.style.setProperty('--sidebar-secondary-text-color', this.colors_variables.side_bar.secondary_text_color);

            document.documentElement.style.setProperty('--header-primary-color', this.colors_variables.header.primary_color);
            document.documentElement.style.setProperty('--header-secondary-color', this.colors_variables.header.secondary_color);
            document.documentElement.style.setProperty('--header-primary-text-color', this.colors_variables.header.primary_text_color);
            document.documentElement.style.setProperty('--header-secondary-text-color', this.colors_variables.header.secondary_text_color);

            document.documentElement.style.setProperty('--button-primary-color', this.colors_variables.button.primary_color);
            document.documentElement.style.setProperty('--button-secondary-color', this.colors_variables.button.secondary_color);
            document.documentElement.style.setProperty('--button-primary-text-color', this.colors_variables.button.primary_text_color);
            document.documentElement.style.setProperty('--button-secondary-text-color', this.colors_variables.button.secondary_text_color);
        },
    },
}
